import React, {useCallback, useEffect, useState} from "react";

const PatientCallResponseOptions = [
    {value: 'patient_will_fill_out', code: 'patient-will-fill-out', label: 'Patient will fill out', bgColor: 'bg-green-100'},
    {value: 'patient_refused', code: 'patient-refused', label: 'Patient refused', bgColor: 'bg-red-100'},
    {value: 'voicemail', code: 'voicemail', label: 'Voicemail', bgColor: 'bg-yellow-100'},
    {value: 'others', code: 'others', label: 'Others', bgColor: 'bg-orange-100'}
];

interface IPhysicianAppointmentPatientCallResponseComponentProps {
    value: {
        appointmentId: string;
        appointmentCallResponse: string | null;
    },
    updateAppointmentPatientCallResponse: (appointmentId: string, appointmentCallResponse: string) => void;
}

const PhysicianAppointmentPatientCallResponseComponent = (props: IPhysicianAppointmentPatientCallResponseComponentProps) => {

    const {value, updateAppointmentPatientCallResponse} = props;
    const {appointmentId, appointmentCallResponse} = value;

    const [selectedOption, setSelectedOption] = useState<string>('');

    useEffect(() => {
        console.log('PhysicianAppointmentPatientCallResponseComponent :: appointmentCallResponse', appointmentCallResponse);
        if (appointmentCallResponse !== null) {
            setSelectedOption(appointmentCallResponse);
        } else {
            setSelectedOption('');
        }
    }, [appointmentCallResponse]);

    const handleResponseChange = useCallback((event: any) => {
        const selectedOptionCode = event?.target?.value;
        setSelectedOption(selectedOptionCode);
        const selectedOption = PatientCallResponseOptions.find(option => option.value === selectedOptionCode)?.value;
        if (selectedOption !== undefined) {
            updateAppointmentPatientCallResponse(appointmentId, selectedOption);
        }
    }, [updateAppointmentPatientCallResponse, appointmentId]);

    return <select
        onChange={handleResponseChange}
        value={selectedOption}
        defaultValue={''}
        className={`block min-w-[180px] w-full outline-none py-1 px-4  placeholder-gray-500 rounded-md border border-gray-300 ${selectedOption ? PatientCallResponseOptions.find(option => option.value === selectedOption)?.bgColor : ''}`}
    >
        <option value={''} disabled={true}>Select</option>
        {
            PatientCallResponseOptions.map((option) => {
                return <option key={option.code} value={option.value}>{option.label}</option>
            })
        }
    </select>
}

export default PhysicianAppointmentPatientCallResponseComponent;


