import { AnyAction } from "redux"
import {
    IPracticePermissions,
    IProvider,
    ISOAPNoteList,
    ISOAPNotePatient,
    ISOAPNotePatients,
    ITherapistsByPractice,
    IUser,
    IUserForm,
    LocationIndex,
    LocationIndexItem,
    LocationIndexRequest,
    SoapNotePatientsFilters,
    UserIndexRequest
} from "../../../models"

export const FETCH_LOCATIONS = "FETCH_LOCATIONS"
export const FETCH_LOCATIONS_IN_PROGRESS = "FETCH_LOCATIONS_IN_PROGRESS"
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS"
export const FETCH_LOCATIONS_FAILED = "FETCH_LOCATIONS_FAILED"

export const FETCH_LOCATION = "FETCH_LOCATION"
export const FETCH_LOCATION_IN_PROGRESS = "FETCH_LOCATION_IN_PROGRESS"
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS"
export const FETCH_LOCATION_FAILED = "FETCH_LOCATION_FAILED"

export const INITIATE_LOCATION_PHONE_VERIFICATION = "INITIATE_LOCATION_PHONE_VERIFICATION"
export const INITIATE_LOCATION_PHONE_VERIFICATION_IN_PROGRESS = "INITIATE_LOCATION_PHONE_VERIFICATION_IN_PROGRESS"
export const INITIATE_LOCATION_PHONE_VERIFICATION_SUCCESS = "INITIATE_LOCATION_PHONE_VERIFICATION_SUCCESS"
export const INITIATE_LOCATION_PHONE_VERIFICATION_FAILED = "INITIATE_LOCATION_PHONE_VERIFICATION_FAILED"
export const CANCEL_POLLING = "CANCEL_POLLING"

export const SAVE_LOCATION = "SAVE_LOCATION"
export const SAVE_LOCATION_IN_PROGRESS = "SAVE_LOCATION_IN_PROGRESS"
export const SAVE_LOCATION_SUCCESS = "SAVE_LOCATION_SUCCESS"
export const SAVE_LOCATION_FAILED = "SAVE_LOCATION_FAILED"
export const CLEAR_LOCATION = "CLEAR_LOCATION"

// User actions
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_IN_PROGRESS = "FETCH_USERS_IN_PROGRESS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILED = "SAVE_USER_FAILED";
export const RESET_SAVE_USER = "RESET_SAVE_USER";

export const FETCH_SOAP_NOTE_PATIENTS = "FETCH_SOAP_NOTE_PATIENTS";
export const FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS = "FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS";
export const FETCH_SOAP_NOTE_PATIENTS_SUCCESS = "FETCH_SOAP_NOTE_PATIENTS_SUCCESS";
export const FETCH_SOAP_NOTE_PATIENTS_FAILED = "FETCH_SOAP_NOTE_PATIENTS_FAILED";

export const FETCH_SOAP_NOTES = "FETCH_SOAP_NOTES";
export const FETCH_SOAP_NOTES_IN_PROGRESS = "FETCH_SOAP_NOTES_IN_PROGRESS";
export const FETCH_SOAP_NOTES_SUCCESS = "FETCH_SOAP_NOTES_SUCCESS";
export const FETCH_SOAP_NOTES_FAILED = "FETCH_SOAP_NOTES_FAILED";

export const MARK_SOAP_NOTE_AS_READ = "MARK_SOAP_NOTE_AS_READ";
export const MARK_SOAP_NOTE_AS_READ_IN_PROGRESS = "MARK_SOAP_NOTE_AS_READ_IN_PROGRESS";
export const MARK_SOAP_NOTE_AS_READ_SUCCESS = "MARK_SOAP_NOTE_AS_READ_SUCCESS";
export const MARK_SOAP_NOTE_AS_READ_FAILED = "MARK_SOAP_NOTE_AS_READ_FAILED";

export const FETCH_SOAP_NOTE_CONTENT = "FETCH_SOAP_NOTE_CONTENT";
export const FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS = "FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS";
export const FETCH_SOAP_NOTE_CONTENT_SUCCESS = "FETCH_SOAP_NOTE_CONTENT_SUCCESS";
export const FETCH_SOAP_NOTE_CONTENT_FAILED = "FETCH_SOAP_NOTE_CONTENT_FAILED";

export const EDIT_SOAP_NOTE_CONTENT = "EDIT_SOAP_NOTE_CONTENT";
export const EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS = "EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS";
export const EDIT_SOAP_NOTE_CONTENT_SUCCESS = "EDIT_SOAP_NOTE_CONTENT_SUCCESS";
export const EDIT_SOAP_NOTE_CONTENT_FAILED = "EDIT_SOAP_NOTE_CONTENT_FAILED";

export const EDIT_SOAP_NOTE_REASON = "EDIT_SOAP_NOTE_REASON";
export const EDIT_SOAP_NOTE_REASON_IN_PROGRESS = "EDIT_SOAP_NOTE_REASON_IN_PROGRESS";
export const EDIT_SOAP_NOTE_REASON_SUCCESS = "EDIT_SOAP_NOTE_REASON_SUCCESS";
export const EDIT_SOAP_NOTE_REASON_FAILED = "EDIT_SOAP_NOTE_REASON_FAILED";

export const SET_LOCATION_ID_TO_INTITATE_CALL = "SET_LOCATION_ID_TO_INTITATE_CALL"
export const CLEAR_LOCATION_ID_TO_INTITATE_CALL = "CLEAR_LOCATION_ID_TO_INTITATE_CALL"

export const FETCH_THERAPISTS_BY_PRACTICE = "FETCH_THERAPISTS_BY_PRACTICE"
export const FETCH_THERAPISTS_BY_PRACTICE_SUCCESS = "FETCH_THERAPISTS_BY_PRACTICE_SUCCESS"
export const FETCH_THERAPISTS_BY_PRACTICE_FAILED = "FETCH_THERAPISTS_BY_PRACTICE_FAILED"
export const FETCH_THERAPISTS_BY_PRACTICE_IN_PROGRESS = "FETCH_THERAPISTS_BY_PRACTICE_IN_PROGRESS"

export const FETCH_PHYSICIANS_BY_PRACTICE = "FETCH_PHYSICIANS_BY_PRACTICE"
export const FETCH_PHYSICIANS_BY_PRACTICE_SUCCESS = "FETCH_PHYSICIANS_BY_PRACTICE_SUCCESS"
export const FETCH_PHYSICIANS_BY_PRACTICE_FAILED = "FETCH_PHYSICIANS_BY_PRACTICE_FAILED"
export const FETCH_PHYSICIANS_BY_PRACTICE_IN_PROGRESS = "FETCH_PHYSICIANS_BY_PRACTICE_IN_PROGRESS"

export const FETCH_PRACTICE_PERMISSIONS = "FETCH_PRACTICE_PERMISSIONS"
export const FETCH_PRACTICE_PERMISSIONS_SUCCESS = "FETCH_PRACTICE_PERMISSIONS_SUCCESS"
export const FETCH_PRACTICE_PERMISSIONS_FAILED = "FETCH_PRACTICE_PERMISSIONS_FAILED"
export const FETCH_PRACTICE_PERMISSIONS_IN_PROGRESS = "FETCH_PRACTICE_PERMISSIONS_IN_PROGRESS"

export const fetchLocations = (request: LocationIndexRequest): AnyAction => {
    return { type: FETCH_LOCATIONS, payload: { request } }
}
export const fetchedLocations = (locations: LocationIndexItem[], locationsCount: number, sideBarTab: string): AnyAction => {
    return { type: FETCH_LOCATIONS_SUCCESS, payload: { locations, locationsCount, sideBarTab } }
}
export const fetchLocationsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_LOCATIONS_FAILED, payload }
}

export const fetchLocation = (locationId: string): AnyAction => {
    return { type: FETCH_LOCATION, payload: { locationId } }
}
export const fetchedLocation = (location: LocationIndexItem): AnyAction => {
    return { type: FETCH_LOCATION_SUCCESS, payload: { location } }
}
export const fetchLocationFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_LOCATION_FAILED, payload }
}

export const fetchLocationInProgress = (): AnyAction => {
    return { type: FETCH_LOCATIONS_IN_PROGRESS }
}

export const initiateLocationPhoneVerification = (locationId: string, phoneNumber: string): AnyAction => {
    return { type: INITIATE_LOCATION_PHONE_VERIFICATION, payload: { locationId, phoneNumber } }
}
export const initiatedLocationPhoneVerification = (code: string): AnyAction => {
    return { type: INITIATE_LOCATION_PHONE_VERIFICATION_SUCCESS, payload: { code } }
}
export const initiateLocationPhoneVerificationFailed = (payload: { error: string }): AnyAction => {
    return { type: INITIATE_LOCATION_PHONE_VERIFICATION_FAILED, payload }
}
export const cancelPolling = () => {
    return { type: CANCEL_POLLING }
}

export const saveLocation = (location: Partial<LocationIndexItem>): AnyAction => {
    return { type: SAVE_LOCATION, payload: { location } }
}
export const savedLocation = (location: LocationIndexItem): AnyAction => {
    return { type: SAVE_LOCATION_SUCCESS, payload: { location } }
}
export const saveLocationFailed = (payload: { error: string }): AnyAction => {
    return { type: SAVE_LOCATION_FAILED, payload }
}
export const clearLocation = (): AnyAction => {
    return { type: CLEAR_LOCATION }
}

export const fetchUsers = (request: UserIndexRequest): AnyAction => {
    return { type: FETCH_USERS, payload: { request } }
}

export const fetchedUsers = (users: IUser[], usersCount: number, sideBarTab: string): AnyAction => {
    return { type: FETCH_USERS_SUCCESS, payload: { users, usersCount, sideBarTab } }
}

export const fetchUsersFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_USERS_FAILED, payload }
}

export const saveUser = (user: Partial<IUserForm>): AnyAction => {
    return { type: SAVE_USER, payload: { user } }
}

export const savedUser = (user: IUser): AnyAction => {
    return { type: SAVE_USER_SUCCESS, payload: { user } }
}

export const saveUserFailed = (payload: { error: string }): AnyAction => {
    return { type: SAVE_USER_FAILED, payload }
}

export const resetSaveUser = (): AnyAction => {
    return { type: RESET_SAVE_USER }
}
export const fetchSoapNotePatientsInProgress = (): AnyAction => {
    return { type: FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS }
}
export const fetchSoapNotePatients = (request: SoapNotePatientsFilters): AnyAction => {
    return { type: FETCH_SOAP_NOTE_PATIENTS, payload: { request } }
}

export const fetchedSoapNotePatients = (SOAPNotepatients: ISOAPNotePatient[], count: number): AnyAction => {
    return { type: FETCH_SOAP_NOTE_PATIENTS_SUCCESS, payload: { SOAPNotepatients, count } }
}

export const fetchSoapNotePatientsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_SOAP_NOTE_PATIENTS_FAILED, payload }
}

export const fetchSoapNotesInProgress = (): AnyAction => {
    return { type: FETCH_SOAP_NOTES_IN_PROGRESS }
}

export const fetchSoapNotes = (patientId: string): AnyAction => {
    return { type: FETCH_SOAP_NOTES, patientId }
}

export const fetchedSoapNotes = (request: ISOAPNoteList): AnyAction => {
    return { type: FETCH_SOAP_NOTES_SUCCESS, payload: { soapNoteList: request } }
}

export const fetchSoapNotesFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_SOAP_NOTES_FAILED, payload }
}

export const markSoapNoteAsRead = (soapId: string): AnyAction => {
    return { type: MARK_SOAP_NOTE_AS_READ, soapId }
}

export const markSoapNoteAsReadInProgress = (): AnyAction => {
    return { type: MARK_SOAP_NOTE_AS_READ_IN_PROGRESS }
}

export const markedSoapNoteAsRead = (soapNote: any): AnyAction => {
    return { type: MARK_SOAP_NOTE_AS_READ_SUCCESS, payload: { soapNote } }
}

export const markSoapNoteAsReadFailed = (payload: { error: string }): AnyAction => {
    return { type: MARK_SOAP_NOTE_AS_READ_FAILED, payload }
}

export const fetchSoapNoteContent = (soapId: string): AnyAction => {
    return { type: FETCH_SOAP_NOTE_CONTENT, soapId }
}

export const fetchSoapNoteContentInProgress = (): AnyAction => {
    return { type: FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS }
}

export const fetchedSoapNoteContent = (soapNote: object): AnyAction => {
    return { type: FETCH_SOAP_NOTE_CONTENT_SUCCESS, payload: { SOAPNoteContent: soapNote } }
}

export const fetchSoapNoteContentFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_SOAP_NOTE_CONTENT_FAILED, payload }
}

export const editSoapNoteContent = (soapId: string, content: object): AnyAction => {
    return { type: EDIT_SOAP_NOTE_CONTENT, payload: { soapId, content } }
}

export const editSoapNoteContentInProgress = (): AnyAction => {
    return { type: EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS }
}

export const editedSoapNoteContent = (soapNote: any): AnyAction => {
    return { type: EDIT_SOAP_NOTE_CONTENT_SUCCESS, payload: { soapNote } }
}

export const editSoapNoteContentFailed = (payload: { error: string }): AnyAction => {
    return { type: EDIT_SOAP_NOTE_CONTENT_FAILED, payload }
}

export const editSoapNoteReason = (soapId: string, reason: object): AnyAction => {
    return { type: EDIT_SOAP_NOTE_REASON, payload: { soapId, reason } }
}

export const editSoapNoteReasonInProgress = (): AnyAction => {
    return { type: EDIT_SOAP_NOTE_REASON_IN_PROGRESS }
}

export const editedSoapNoteReason = (soapNote: any): AnyAction => {
    return { type: EDIT_SOAP_NOTE_REASON_SUCCESS, payload: { soapNote } }
}

export const editSoapNoteReasonFailed = (payload: { error: string }): AnyAction => {
    return { type: EDIT_SOAP_NOTE_REASON_FAILED, payload }
}

export const setLocationIdToInitiateCall = (locationId?: string): AnyAction => {
    return { type: SET_LOCATION_ID_TO_INTITATE_CALL, payload: { locationId } }
}
export const clearLocationIdToInitiateCall = (): AnyAction => {
    return { type: CLEAR_LOCATION_ID_TO_INTITATE_CALL }
}

export const fetchTherapistsByPractice = (practiceId: string): AnyAction => {
    return { type: FETCH_THERAPISTS_BY_PRACTICE, payload: { practiceId } }
}
export const fetchedTherapistsByPractice = (therapists: ITherapistsByPractice[]): AnyAction => {
    return { type: FETCH_THERAPISTS_BY_PRACTICE_SUCCESS, payload: { therapists } }
}
export const fetchTherapistsByPracticeFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_THERAPISTS_BY_PRACTICE_FAILED, payload }
}
export const fetchTherapistsByPracticeInProgress = (): AnyAction => {
    return { type: FETCH_THERAPISTS_BY_PRACTICE_IN_PROGRESS }
}

export const fetchPhysiciansByPractice = (practiceId: string): AnyAction => {
    return { type: FETCH_PHYSICIANS_BY_PRACTICE, payload: { practiceId } }
}
export const fetchedPhysiciansByPractice = (physicians: IProvider[]): AnyAction => {
    return { type: FETCH_PHYSICIANS_BY_PRACTICE_SUCCESS, payload: { physicians } }
}
export const fetchPhysiciansByPracticeFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PHYSICIANS_BY_PRACTICE_FAILED, payload }
}
export const fetchPhysiciansByPracticeInProgress = (): AnyAction => {
    return { type: FETCH_PHYSICIANS_BY_PRACTICE_IN_PROGRESS }
}

export const fetchPracticePermissions = (): AnyAction => {
    return { type: FETCH_PRACTICE_PERMISSIONS }
}
export const fetchedPracticePermissions = (permissions: IPracticePermissions): AnyAction => {
    return { type: FETCH_PRACTICE_PERMISSIONS_SUCCESS, payload: { permissions } }
}
export const fetchPracticePermissionsFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PRACTICE_PERMISSIONS_FAILED, payload }
}
export const fetchPracticePermissionsInProgress = (): AnyAction => {
    return { type: FETCH_PRACTICE_PERMISSIONS_IN_PROGRESS }
}