import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, legacy_createStore as createStore, PreloadedState } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import { rootSaga } from "./sagas";
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import { PractitionerAccountParams } from "./reducers/counsellor/account.reducer";
import {
    CANCEL_APPOINTMENT,
    COMPLETE_INTAKE_SESSION,
    COMPLETED_INTAKE_SESSION,
    CREATE_APPOINTMENT,
    FETCH_ACE_SCREENER,
    FETCH_APPOINTMENTS,
    FETCH_DAILY_CHECKIN_INSIGHTS,
    FETCH_PATIENT,
    FETCH_PATIENT_JOURNEY,
    FETCH_PATIENTS,
    FETCH_PRACTICES,
    FETCH_PROGRESS_NOTES,
    FETCH_PROSPECT,
    FETCH_PROSPECT_REFERENCE_DATA,
    FETCH_PROSPECT_SCREENER_RESPONSE,
    FETCH_PROSPECT_SESSION_BY_SRC_PATIENT,
    FETCH_PROSPECTS,
    FETCH_SAFETY_PLAN_NOTES,
    FETCH_SESSION,
    FETCH_SESSION_INSIGHTS,
    FETCH_TREATMENT_NOTES,
    INITIATE_CALL,
    INTAKE_FORM_SAVE,
    LOCK_INTAKE_FORM,
    RESET_PROSPECT,
    REVIEW_AND_SUBMIT_TREATMENT_PLAN,
    SAVE_PROGRESS_NOTES,
    SAVE_SAFETY_PLAN_NOTES,
    SAVE_TREATMENT_NOTES,
    SEARCH_PATIENT,
    unauthorizedAccessForYourRole,
    UPDATE_NOTE_STATUS,
    UPDATE_SESSION_CALL_LOG,
    FETCH_PROSPECT_WITH_SUMMARY,
    CARE_COORDINATOR_INITIATE_CALL,
    FETCH_SCREENER_COMPLIANCE_STATS,
    FETCH_PATIENT_WITH_INTAKE
} from "./actions/counsellor";
import { AccountParams } from "./reducers/account.reducer";
import { GENERATE_QR_CODE, UPDATE_PHONE_NUMBER } from "./actions/physician/account.action";
import {
    PHYSICIAN_DOWNLOAD_BILLING_REPORT,
    PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES,
    PHYSICIAN_FETCH_ALL_THERAPISTS,
    PHYSICIAN_FETCH_BILLING_PATIENTS, PHYSICIAN_FETCH_COCM_PATIENTS,
    PHYSICIAN_FETCH_PATIENT,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A,
    PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY,
    PHYSICIAN_FETCH_PATIENTS,
    PHYSICIAN_FETCH_REFERENCE_NOTES,
    PHYSICIAN_FETCH_REFERRED_PATIENTS,
    PHYSICIAN_SAVE_REFER_NOTES,
    PHYSICIAN_SAVE_SCHEDULE,
    DOWNLOAD_PATIENT_SUMMARY,
    DOWNLOAD_PATIENT_SESSION_NOTES,
    PHYSICIAN_FETCH_ICD_CODES, SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT
} from "./actions/physician/patients.action";
import { AuthState } from "../models";
import {
    FETCH_ASSIGNED_SCREENERS, FETCH_CATCHUP_NOTES, FETCH_PATIENT_BY_SOURCE_PATIENT_ID,
    FETCH_PSYCHIATRIST_NOTES, FETCH_SCREENER_Q_AND_A, SAVE_CATCHUP_NOTES, SAVE_SCREENER_ANSWER,
    SAVE_PSYCHIATRIST_NOTES, UPDATE_LATEST_NOTES_STATUS, UPDATE_PATIENT_INFO, FETCH_PATIENT_SUMMARY,
    FETCH_PATIENT_WITH_SUMMARY, SEARCH_PATIENTS, FETCH_PRACTICE_PATIENT, FETCH_PATIENT_LOCATION,
    UPDATE_PATIENT_STATUS_FOR_DISCHARGE,
    SEARCH_DISCHARGED_PATIENTS,
    FETCH_SOURCE_PATIENT_DETAILS,
    FETCH_SW_SCREENERS_INSIGHTS,
    FETCH_PATIENT_SCREENING_SUMMARY
} from "./actions/counsellor/patients.action";
import { CLEAR_CREATE_APPOINTMENT_ERROR, CREATE_BACKDATED_APPOINTMENT, FETCH_THERAPISTS, SEARCH_APPOINTMENTS, UPDATE_SESSION_STATUS } from './actions/counsellor/appointments.action';
import {
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY,
    FETCH_POTENTIAL_REFERRAL_PATIENTS,
    FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED, FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS,
    FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS
} from "./actions/care-coordinator";
import { FETCH_PRACTICE_DETAILS } from "./actions/counsellor/practice.action";
import {
    EDIT_SOAP_NOTE_CONTENT,
    EDIT_SOAP_NOTE_CONTENT_FAILED,
    EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS,
    EDIT_SOAP_NOTE_CONTENT_SUCCESS,
    EDIT_SOAP_NOTE_REASON,
    EDIT_SOAP_NOTE_REASON_FAILED,
    EDIT_SOAP_NOTE_REASON_IN_PROGRESS,
    EDIT_SOAP_NOTE_REASON_SUCCESS,
    FETCH_LOCATION,
    FETCH_LOCATIONS,
    FETCH_PHYSICIANS_BY_PRACTICE,
    FETCH_PRACTICE_PERMISSIONS,
    FETCH_SOAP_NOTE_CONTENT,
    FETCH_SOAP_NOTE_CONTENT_FAILED,
    FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS,
    FETCH_SOAP_NOTE_CONTENT_SUCCESS,
    FETCH_SOAP_NOTE_PATIENTS,
    FETCH_SOAP_NOTE_PATIENTS_FAILED,
    FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS,
    FETCH_SOAP_NOTE_PATIENTS_SUCCESS,
    FETCH_SOAP_NOTES,
    FETCH_SOAP_NOTES_FAILED,
    FETCH_SOAP_NOTES_IN_PROGRESS,
    FETCH_SOAP_NOTES_SUCCESS,
    FETCH_THERAPISTS_BY_PRACTICE,
    FETCH_USERS, FETCH_USERS_FAILED, FETCH_USERS_IN_PROGRESS, FETCH_USERS_SUCCESS,
    INITIATE_LOCATION_PHONE_VERIFICATION,
    MARK_SOAP_NOTE_AS_READ,
    MARK_SOAP_NOTE_AS_READ_FAILED,
    MARK_SOAP_NOTE_AS_READ_IN_PROGRESS,
    MARK_SOAP_NOTE_AS_READ_SUCCESS,
    SAVE_LOCATION, SAVE_USER, SAVE_USER_FAILED, SAVE_USER_SUCCESS
} from './actions/physician/admin-operations.action';


import {
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK,
    FETCH_PATIENT_BASIC_INFORMATION,
    FETCH_PATIENT_MASTER_LIST,
    FETCH_PATIENT_MASTER_LIST_FAILED,
    FETCH_PATIENT_MASTER_LIST_SUCCESS,
    TRANSFER_INTAKE,
    TRANSFER_INTAKE_FAILED,
    TRANSFER_INTAKE_SUCCESS,
    FETCH_INSURANCE_LIST,
    FETCH_INSURANCE_LIST_SUCCESS,
    FETCH_INSURANCE_LIST_FAILED,
    FETCH_INSURANCE_LIST_IN_PROGRESS,
    CHECK_INSURANCE_ELIGIBILITY,
    CHECK_INSURANCE_ELIGIBILITY_FAILED,
    CHECK_INSURANCE_ELIGIBILITY_SUCCESS,
    CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED,
    FETCH_REFERRED_PATIENTS,
    FETCH_REFERRED_PATIENTS_SUCCESS,
    FETCH_REFERRED_PATIENTS_FAILED,
    FETCH_REFERRED_IN_PROGRESS,
    ADD_PHYSICIAN_APPOINTMENT,
    FETCH_PHYSICIAN_APPOINTMENTS,
    SEND_SCREENER_LINK_TO_PATIENT,
    RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS,
    FETCH_PATIENT_ALL_SCREENING_SESSIONS,
    UPDATE_PHYSICIAN_APPOINTMENT, SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
    UPDATE_INSURANCE_STATUS,
    UPDATE_INSURANCE_STATUS_SUCCESS,
    UPDATE_INSURANCE_STATUS_IN_PROGRESS, DELETE_PHYSICIAN_APPOINTMENT
} from "./actions/care-coordinator/patients.action";

import { PhysicianAccountParams } from "./reducers/physician/account.reducer";
import { FETCH_CLINICAL_IMPROVEMENT_METRICS, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, FETCH_DASHBOARD_PATIENTS_SUMMARY, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, FETCH_REGISTRY_BASELINE_METRICS, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION, FETCH_REGISTRY_CLINICAL_CONDITIONS, FETCH_REGISTRY_NOTES_METRICS, FETCH_REGISTRY_STATICS_CARD } from './actions/physician/dashboard.action';

import { CREATE_OR_FETCH_INTAKE } from "./actions/counsellor/intake.action";
import { FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID, FETCH_PSYCHIATRIST_SOCIAL_WORKERS } from './actions/counsellor/prospects.action';
import {
    FETCH_CLINICAL_NOTES_COMPLIANCE_STATS,
    FETCH_PENDING_CLINICAL_NOTES,
    FETCH_SCREENER_PENDING_PATIENTS
} from "./actions/counsellor/non-physician-compliance.action";
import { FETCH_COMPLIANCE_SW_LIST } from './actions/care-coordinator/compliance.action';
import { PHYSICIAN_INITIATE_CALL } from './actions/physician/calls.action';
import { FETCH_PRACTICE_MASTER_LIST } from "./actions/care-coordinator/master-data.action";

const ACTIONS_REQUIRING_AUTH = [
    FETCH_PROSPECTS, FETCH_APPOINTMENTS, FETCH_PROSPECT, FETCH_PROSPECT_SCREENER_RESPONSE, SEARCH_PATIENT, CREATE_APPOINTMENT, COMPLETE_INTAKE_SESSION, COMPLETED_INTAKE_SESSION,
    FETCH_PATIENT, FETCH_PATIENTS, UPDATE_SESSION_CALL_LOG, FETCH_PROSPECT_REFERENCE_DATA, FETCH_ACE_SCREENER, INTAKE_FORM_SAVE, FETCH_PROSPECT_SESSION_BY_SRC_PATIENT, LOCK_INTAKE_FORM, FETCH_TREATMENT_NOTES,
    CANCEL_APPOINTMENT, SAVE_TREATMENT_NOTES, FETCH_PROGRESS_NOTES, SAVE_PROGRESS_NOTES, REVIEW_AND_SUBMIT_TREATMENT_PLAN, FETCH_SAFETY_PLAN_NOTES, SAVE_SAFETY_PLAN_NOTES,
    FETCH_PATIENT_JOURNEY, FETCH_SESSION_INSIGHTS, FETCH_DAILY_CHECKIN_INSIGHTS, FETCH_PRACTICES, PHYSICIAN_FETCH_PATIENTS, PHYSICIAN_FETCH_PATIENT, PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A, PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES, PHYSICIAN_SAVE_REFER_NOTES, PHYSICIAN_FETCH_REFERRED_PATIENTS, PHYSICIAN_FETCH_REFERENCE_NOTES,
    PHYSICIAN_FETCH_ALL_THERAPISTS, PHYSICIAN_SAVE_SCHEDULE, PHYSICIAN_FETCH_BILLING_PATIENTS, PHYSICIAN_DOWNLOAD_BILLING_REPORT, GENERATE_QR_CODE, FETCH_PATIENT_BY_SOURCE_PATIENT_ID, UPDATE_NOTE_STATUS,
    FETCH_SESSION, UPDATE_SESSION_STATUS, FETCH_CATCHUP_NOTES, SAVE_CATCHUP_NOTES, SAVE_PSYCHIATRIST_NOTES, FETCH_PSYCHIATRIST_NOTES, FETCH_ASSIGNED_SCREENERS, FETCH_SCREENER_Q_AND_A, SAVE_SCREENER_ANSWER, INITIATE_CALL,
    UPDATE_PHONE_NUMBER, UPDATE_PATIENT_INFO, FETCH_PATIENT_SUMMARY, FETCH_POTENTIAL_REFERRAL_PATIENTS, FETCH_PATIENT_WITH_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY, FETCH_PRACTICE_DETAILS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY, FETCH_LOCATIONS, SAVE_LOCATION, SEARCH_APPOINTMENTS, SEARCH_PATIENTS, CARE_COORDINATOR_INITIATE_CALL, FETCH_LOCATION,
    INITIATE_LOCATION_PHONE_VERIFICATION, FETCH_PATIENT_BASIC_INFORMATION,
    PHYSICIAN_FETCH_COCM_PATIENTS, FETCH_PRACTICE_PATIENT, FETCH_DASHBOARD_PATIENTS_SUMMARY, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE,
    DOWNLOAD_PATIENT_SUMMARY, FETCH_PATIENT_LOCATION, UPDATE_PATIENT_STATUS_FOR_DISCHARGE, SEARCH_DISCHARGED_PATIENTS, FETCH_USERS, FETCH_USERS_IN_PROGRESS, FETCH_USERS_SUCCESS, FETCH_USERS_FAILED, SAVE_USER, SAVE_USER_FAILED, SAVE_USER_SUCCESS, FETCH_THERAPISTS,
    FETCH_SOURCE_PATIENT_DETAILS, FETCH_SCREENER_COMPLIANCE_STATS, FETCH_PATIENT_WITH_INTAKE, CREATE_OR_FETCH_INTAKE, DOWNLOAD_PATIENT_SESSION_NOTES,
    FETCH_PATIENT_MASTER_LIST_FAILED, FETCH_PATIENT_MASTER_LIST_SUCCESS, FETCH_PATIENT_MASTER_LIST, TRANSFER_INTAKE, TRANSFER_INTAKE_SUCCESS, TRANSFER_INTAKE_FAILED,
    FETCH_SCREENER_PENDING_PATIENTS, FETCH_CLINICAL_NOTES_COMPLIANCE_STATS, FETCH_PENDING_CLINICAL_NOTES, FETCH_PSYCHIATRIST_SOCIAL_WORKERS,
    CREATE_BACKDATED_APPOINTMENT, FETCH_SOAP_NOTE_PATIENTS, FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS, FETCH_SOAP_NOTE_PATIENTS_SUCCESS, FETCH_SOAP_NOTE_PATIENTS_FAILED,
    FETCH_SOAP_NOTES, FETCH_SOAP_NOTES_IN_PROGRESS, FETCH_SOAP_NOTES_SUCCESS, FETCH_SOAP_NOTES_FAILED,
    MARK_SOAP_NOTE_AS_READ, MARK_SOAP_NOTE_AS_READ_IN_PROGRESS, MARK_SOAP_NOTE_AS_READ_SUCCESS, MARK_SOAP_NOTE_AS_READ_FAILED,
    FETCH_SOAP_NOTE_CONTENT, FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS, FETCH_SOAP_NOTE_CONTENT_SUCCESS, FETCH_SOAP_NOTE_CONTENT_FAILED,
    EDIT_SOAP_NOTE_CONTENT, EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS, EDIT_SOAP_NOTE_CONTENT_SUCCESS, EDIT_SOAP_NOTE_CONTENT_FAILED,
    EDIT_SOAP_NOTE_REASON, EDIT_SOAP_NOTE_REASON_IN_PROGRESS, EDIT_SOAP_NOTE_REASON_SUCCESS, EDIT_SOAP_NOTE_REASON_FAILED,
    FETCH_SW_SCREENERS_INSIGHTS, FETCH_COMPLIANCE_SW_LIST, PHYSICIAN_FETCH_ICD_CODES,
    FETCH_INSURANCE_LIST, FETCH_INSURANCE_LIST_SUCCESS, FETCH_INSURANCE_LIST_FAILED, FETCH_INSURANCE_LIST_IN_PROGRESS,
    CHECK_INSURANCE_ELIGIBILITY, CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS, CHECK_INSURANCE_ELIGIBILITY_SUCCESS, CHECK_INSURANCE_ELIGIBILITY_FAILED,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS, UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS, UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED,
    FETCH_REFERRED_PATIENTS, FETCH_REFERRED_PATIENTS_SUCCESS, FETCH_REFERRED_PATIENTS_FAILED, FETCH_REFERRED_IN_PROGRESS,
    FETCH_PATIENT_SCREENING_SUMMARY,
    FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID, FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, SEND_SCREENER_LINK_TO_PATIENT, PHYSICIAN_INITIATE_CALL, SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT,
    FETCH_PHYSICIAN_APPOINTMENTS, ADD_PHYSICIAN_APPOINTMENT, FETCH_PRACTICE_MASTER_LIST, UPDATE_PHYSICIAN_APPOINTMENT, SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
    FETCH_REGISTRY_STATICS_CARD, FETCH_PHYSICIANS_BY_PRACTICE, FETCH_THERAPISTS_BY_PRACTICE, FETCH_PATIENT_ALL_SCREENING_SESSIONS,
    FETCH_PHYSICIAN_APPOINTMENTS, ADD_PHYSICIAN_APPOINTMENT, FETCH_PRACTICE_MASTER_LIST, UPDATE_PHYSICIAN_APPOINTMENT, SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
    FETCH_REGISTRY_STATICS_CARD, FETCH_REGISTRY_NOTES_METRICS, FETCH_CLINICAL_IMPROVEMENT_METRICS, FETCH_PHYSICIANS_BY_PRACTICE, FETCH_THERAPISTS_BY_PRACTICE,
    FETCH_REGISTRY_CLINICAL_CONDITIONS, FETCH_REGISTRY_BASELINE_METRICS, FETCH_PRACTICE_PERMISSIONS, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION,
    UPDATE_INSURANCE_STATUS, UPDATE_INSURANCE_STATUS_SUCCESS, UPDATE_INSURANCE_STATUS_IN_PROGRESS, DELETE_PHYSICIAN_APPOINTMENT
]

const ACTIONS_ALLOWED_BY_ROLE: { [key: string]: string[] } = {
    Admin: [
        FETCH_PROSPECTS, FETCH_APPOINTMENTS, FETCH_PROSPECT, FETCH_PROSPECT_SCREENER_RESPONSE, SEARCH_PATIENT, CREATE_APPOINTMENT, COMPLETE_INTAKE_SESSION, COMPLETED_INTAKE_SESSION,
        FETCH_PATIENT, FETCH_PATIENTS, UPDATE_SESSION_CALL_LOG, FETCH_PROSPECT_REFERENCE_DATA, FETCH_ACE_SCREENER, INTAKE_FORM_SAVE, FETCH_PROSPECT_SESSION_BY_SRC_PATIENT, LOCK_INTAKE_FORM, FETCH_TREATMENT_NOTES,
        CANCEL_APPOINTMENT, SAVE_TREATMENT_NOTES, FETCH_PROGRESS_NOTES, SAVE_PROGRESS_NOTES, REVIEW_AND_SUBMIT_TREATMENT_PLAN, FETCH_SAFETY_PLAN_NOTES, SAVE_SAFETY_PLAN_NOTES,
        FETCH_PATIENT_JOURNEY, RESET_PROSPECT, FETCH_SESSION_INSIGHTS, FETCH_DAILY_CHECKIN_INSIGHTS, FETCH_PRACTICES, FETCH_SESSION, UPDATE_NOTE_STATUS, UPDATE_LATEST_NOTES_STATUS, UPDATE_SESSION_STATUS,
        FETCH_CATCHUP_NOTES, SAVE_CATCHUP_NOTES, FETCH_PSYCHIATRIST_NOTES, SAVE_PSYCHIATRIST_NOTES, FETCH_ASSIGNED_SCREENERS, FETCH_SCREENER_Q_AND_A, SAVE_SCREENER_ANSWER, PHYSICIAN_FETCH_PATIENT_Q_AND_A, INITIATE_CALL,
        UPDATE_PATIENT_INFO, FETCH_PATIENT_WITH_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY, FETCH_PRACTICE_DETAILS, SEARCH_APPOINTMENTS, SEARCH_PATIENTS, FETCH_PATIENT_BASIC_INFORMATION,
        FETCH_PATIENT_LOCATION, UPDATE_PATIENT_STATUS_FOR_DISCHARGE, FETCH_PATIENT_SUMMARY, SEARCH_DISCHARGED_PATIENTS, FETCH_USERS, FETCH_USERS_IN_PROGRESS, FETCH_USERS_SUCCESS, FETCH_USERS_FAILED, SAVE_USER, SAVE_USER_FAILED, SAVE_USER_SUCCESS, FETCH_SOURCE_PATIENT_DETAILS,
        FETCH_PATIENT_WITH_INTAKE, CREATE_OR_FETCH_INTAKE, FETCH_SCREENER_PENDING_PATIENTS, FETCH_CLINICAL_NOTES_COMPLIANCE_STATS,
        CREATE_BACKDATED_APPOINTMENT, FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, SEND_SCREENER_LINK_TO_PATIENT, SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT
    ],
    Counsellor: [
        FETCH_PROSPECTS, FETCH_APPOINTMENTS, FETCH_PROSPECT, FETCH_PROSPECT_SCREENER_RESPONSE, SEARCH_PATIENT, CREATE_APPOINTMENT, COMPLETE_INTAKE_SESSION, COMPLETED_INTAKE_SESSION,
        FETCH_PATIENT, FETCH_PATIENTS, UPDATE_SESSION_CALL_LOG, FETCH_PROSPECT_REFERENCE_DATA, FETCH_ACE_SCREENER, INTAKE_FORM_SAVE, FETCH_PROSPECT_SESSION_BY_SRC_PATIENT, LOCK_INTAKE_FORM, FETCH_TREATMENT_NOTES,
        CANCEL_APPOINTMENT, SAVE_TREATMENT_NOTES, FETCH_PROGRESS_NOTES, SAVE_PROGRESS_NOTES, REVIEW_AND_SUBMIT_TREATMENT_PLAN, FETCH_SAFETY_PLAN_NOTES, SAVE_SAFETY_PLAN_NOTES,
        FETCH_PATIENT_JOURNEY, RESET_PROSPECT, FETCH_SESSION_INSIGHTS, FETCH_DAILY_CHECKIN_INSIGHTS, FETCH_PRACTICES, FETCH_SESSION, UPDATE_NOTE_STATUS, UPDATE_LATEST_NOTES_STATUS, UPDATE_SESSION_STATUS,
        FETCH_CATCHUP_NOTES, SAVE_CATCHUP_NOTES, FETCH_PSYCHIATRIST_NOTES, SAVE_PSYCHIATRIST_NOTES, FETCH_ASSIGNED_SCREENERS, FETCH_SCREENER_Q_AND_A, SAVE_SCREENER_ANSWER, PHYSICIAN_FETCH_PATIENT_Q_AND_A, INITIATE_CALL,
        UPDATE_PATIENT_INFO, FETCH_PATIENT_WITH_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY, FETCH_PRACTICE_DETAILS, SEARCH_APPOINTMENTS, SEARCH_PATIENTS,
        FETCH_PATIENT_LOCATION, UPDATE_PATIENT_STATUS_FOR_DISCHARGE, FETCH_PATIENT_SUMMARY, SEARCH_DISCHARGED_PATIENTS, FETCH_SOURCE_PATIENT_DETAILS, FETCH_SCREENER_COMPLIANCE_STATS, FETCH_PATIENT_WITH_INTAKE, CREATE_OR_FETCH_INTAKE, FETCH_SCREENER_PENDING_PATIENTS, FETCH_CLINICAL_NOTES_COMPLIANCE_STATS,
        FETCH_PENDING_CLINICAL_NOTES, CREATE_BACKDATED_APPOINTMENT, FETCH_SW_SCREENERS_INSIGHTS, FETCH_PATIENT_SCREENING_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID
    ],
    Psychiatrist: [
        FETCH_PROSPECT, FETCH_PROSPECT_SCREENER_RESPONSE, FETCH_PROSPECT_SESSION_BY_SRC_PATIENT, FETCH_PATIENT, FETCH_PATIENTS, FETCH_PROSPECT_REFERENCE_DATA, FETCH_ACE_SCREENER, FETCH_TREATMENT_NOTES,
        FETCH_PROGRESS_NOTES, FETCH_SAFETY_PLAN_NOTES, FETCH_PATIENT_JOURNEY, FETCH_SESSION_INSIGHTS, FETCH_DAILY_CHECKIN_INSIGHTS, FETCH_PRACTICES, SAVE_TREATMENT_NOTES,
        SAVE_PROGRESS_NOTES, REVIEW_AND_SUBMIT_TREATMENT_PLAN, FETCH_SAFETY_PLAN_NOTES, SAVE_SAFETY_PLAN_NOTES, UPDATE_NOTE_STATUS, UPDATE_LATEST_NOTES_STATUS, INTAKE_FORM_SAVE, FETCH_SESSION,
        FETCH_CATCHUP_NOTES, SAVE_CATCHUP_NOTES, SAVE_PSYCHIATRIST_NOTES, FETCH_PSYCHIATRIST_NOTES, SEARCH_PATIENT, CREATE_APPOINTMENT, FETCH_ASSIGNED_SCREENERS, FETCH_SCREENER_Q_AND_A, SAVE_SCREENER_ANSWER, PHYSICIAN_FETCH_PATIENT_Q_AND_A,
        FETCH_PATIENT_WITH_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY, SEARCH_PATIENTS, SEARCH_DISCHARGED_PATIENTS, FETCH_PATIENT_SUMMARY, FETCH_SOURCE_PATIENT_DETAILS, FETCH_PATIENT_WITH_INTAKE, CREATE_OR_FETCH_INTAKE,
        FETCH_PROSPECTS, FETCH_PSYCHIATRIST_SOCIAL_WORKERS, FETCH_PATIENT_SCREENING_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID
    ],
    Physician: [
        PHYSICIAN_FETCH_PATIENTS, PHYSICIAN_FETCH_PATIENT, PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY, PHYSICIAN_FETCH_PATIENT_Q_AND_A, PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES,
        PHYSICIAN_SAVE_REFER_NOTES, PHYSICIAN_FETCH_REFERRED_PATIENTS, PHYSICIAN_FETCH_REFERENCE_NOTES, PHYSICIAN_FETCH_ALL_THERAPISTS, PHYSICIAN_SAVE_SCHEDULE,
        PHYSICIAN_FETCH_BILLING_PATIENTS, PHYSICIAN_DOWNLOAD_BILLING_REPORT, GENERATE_QR_CODE, FETCH_PATIENT_JOURNEY, FETCH_SESSION_INSIGHTS, FETCH_DAILY_CHECKIN_INSIGHTS,
        FETCH_TREATMENT_NOTES, FETCH_PROGRESS_NOTES, FETCH_SAFETY_PLAN_NOTES, FETCH_PATIENT, FETCH_PATIENT_BY_SOURCE_PATIENT_ID, FETCH_PROSPECT, FETCH_PROSPECT_SESSION_BY_SRC_PATIENT,
        FETCH_PROSPECT_REFERENCE_DATA, FETCH_PATIENTS, FETCH_SESSION, FETCH_CATCHUP_NOTES, FETCH_PSYCHIATRIST_NOTES, UPDATE_PHONE_NUMBER, UPDATE_PATIENT_INFO,
        FETCH_PATIENT_SUMMARY, FETCH_LOCATIONS, SAVE_LOCATION, FETCH_LOCATION, INITIATE_LOCATION_PHONE_VERIFICATION, SEARCH_PATIENTS, PHYSICIAN_FETCH_COCM_PATIENTS, FETCH_PRACTICE_PATIENT,
        FETCH_DASHBOARD_PATIENTS_SUMMARY, FETCH_DASHBOARD_PATIENTS_SUMMARY, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE,
        DOWNLOAD_PATIENT_SUMMARY, SEARCH_DISCHARGED_PATIENTS, FETCH_USERS, FETCH_USERS_IN_PROGRESS, FETCH_USERS_SUCCESS, FETCH_USERS_FAILED, SAVE_USER, SAVE_USER_FAILED, SAVE_USER_SUCCESS, FETCH_SOURCE_PATIENT_DETAILS, FETCH_PATIENT_WITH_INTAKE,
        DOWNLOAD_PATIENT_SESSION_NOTES, FETCH_SOAP_NOTE_PATIENTS, FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS, FETCH_SOAP_NOTE_PATIENTS_SUCCESS, FETCH_SOAP_NOTE_PATIENTS_FAILED,
        FETCH_SOAP_NOTES, FETCH_SOAP_NOTES_IN_PROGRESS, FETCH_SOAP_NOTES_SUCCESS, FETCH_SOAP_NOTES_FAILED,
        MARK_SOAP_NOTE_AS_READ, MARK_SOAP_NOTE_AS_READ_IN_PROGRESS, MARK_SOAP_NOTE_AS_READ_SUCCESS, MARK_SOAP_NOTE_AS_READ_FAILED,
        FETCH_SOAP_NOTE_CONTENT, FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS, FETCH_SOAP_NOTE_CONTENT_SUCCESS, FETCH_SOAP_NOTE_CONTENT_FAILED,
        EDIT_SOAP_NOTE_CONTENT, EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS, EDIT_SOAP_NOTE_CONTENT_SUCCESS, EDIT_SOAP_NOTE_CONTENT_FAILED,
        EDIT_SOAP_NOTE_REASON, EDIT_SOAP_NOTE_REASON_IN_PROGRESS, EDIT_SOAP_NOTE_REASON_SUCCESS, EDIT_SOAP_NOTE_REASON_FAILED, FETCH_SW_SCREENERS_INSIGHTS,
        PHYSICIAN_FETCH_ICD_CODES, FETCH_PATIENT_LOCATION, PHYSICIAN_INITIATE_CALL, SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT, FETCH_REGISTRY_STATICS_CARD,
        FETCH_PHYSICIANS_BY_PRACTICE, FETCH_THERAPISTS_BY_PRACTICE,FETCH_REGISTRY_NOTES_METRICS, FETCH_CLINICAL_IMPROVEMENT_METRICS, FETCH_REGISTRY_CLINICAL_CONDITIONS,
        FETCH_REGISTRY_BASELINE_METRICS, FETCH_PRACTICE_PERMISSIONS, FETCH_REGISTRY_CLINICAL_CONDITION_DISTRIBUTION
    ],
    Care_Coordinator: [
        FETCH_POTENTIAL_REFERRAL_PATIENTS, FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS, FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED, FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS,
        FETCH_PRACTICE_DETAILS, FETCH_PATIENT_LATEST_SCREENING_SUMMARY, UPDATE_PATIENT_INFO, SEARCH_APPOINTMENTS, CARE_COORDINATOR_INITIATE_CALL, SEARCH_PATIENTS,
        FETCH_PATIENT_BASIC_INFORMATION, FETCH_PATIENT_WITH_SUMMARY, FETCH_PROSPECTS, FETCH_PROSPECT_WITH_SUMMARY, FETCH_PATIENT, FETCH_PATIENT_JOURNEY, FETCH_PROSPECT_SCREENER_RESPONSE, FETCH_PRACTICES,
        FETCH_PATIENT_LOCATION, SEARCH_DISCHARGED_PATIENTS, FETCH_THERAPISTS, CREATE_APPOINTMENT, CLEAR_CREATE_APPOINTMENT_ERROR, FETCH_SESSION, CANCEL_APPOINTMENT,
        FETCH_SOURCE_PATIENT_DETAILS, FETCH_SCREENER_COMPLIANCE_STATS, FETCH_PATIENT_MASTER_LIST_FAILED, FETCH_PATIENT_MASTER_LIST_SUCCESS, FETCH_PATIENT_MASTER_LIST, TRANSFER_INTAKE, TRANSFER_INTAKE_SUCCESS, TRANSFER_INTAKE_FAILED,
        FETCH_SCREENER_PENDING_PATIENTS, FETCH_COMPLIANCE_SW_LIST, FETCH_PENDING_CLINICAL_NOTES, FETCH_CLINICAL_NOTES_COMPLIANCE_STATS,
        FETCH_PATIENT_SCREENING_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID, FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, SEND_SCREENER_LINK_TO_PATIENT, FETCH_PHYSICIAN_APPOINTMENTS, ADD_PHYSICIAN_APPOINTMENT, FETCH_PRACTICE_MASTER_LIST, UPDATE_PHYSICIAN_APPOINTMENT,
        FETCH_SCREENER_PENDING_PATIENTS, FETCH_COMPLIANCE_SW_LIST, FETCH_PENDING_CLINICAL_NOTES, FETCH_CLINICAL_NOTES_COMPLIANCE_STATS, FETCH_INSURANCE_LIST, FETCH_INSURANCE_LIST_SUCCESS, FETCH_INSURANCE_LIST_FAILED, FETCH_INSURANCE_LIST_IN_PROGRESS,
        CHECK_INSURANCE_ELIGIBILITY, CHECK_INSURANCE_ELIGIBILITY_IN_PROGRESS, CHECK_INSURANCE_ELIGIBILITY_SUCCESS, CHECK_INSURANCE_ELIGIBILITY_FAILED,
        UPDATE_ELIGIBILITY_INSURANCE_STATUS, UPDATE_ELIGIBILITY_INSURANCE_STATUS_SUCCESS, UPDATE_ELIGIBILITY_INSURANCE_STATUS_FAILED,
        FETCH_REFERRED_PATIENTS, FETCH_REFERRED_PATIENTS_SUCCESS, FETCH_REFERRED_PATIENTS_FAILED, FETCH_REFERRED_IN_PROGRESS, RESET_CHECKED_INSURANCE_ELIGIBILITY_SUCCESS,
        FETCH_PATIENT_SCREENING_SUMMARY, FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID, FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, SEND_SCREENER_LINK_TO_PATIENT,
        FETCH_PHYSICIAN_APPOINTMENTS, ADD_PHYSICIAN_APPOINTMENT, FETCH_PRACTICE_MASTER_LIST, SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
        UPDATE_INSURANCE_STATUS,UPDATE_INSURANCE_STATUS_SUCCESS,UPDATE_INSURANCE_STATUS_IN_PROGRESS, FETCH_PATIENT_ALL_SCREENING_SESSIONS, DELETE_PHYSICIAN_APPOINTMENT
    ],
    Medical_Assistant: [
        PHYSICIAN_FETCH_PATIENTS, PHYSICIAN_FETCH_PATIENT, PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY, PHYSICIAN_FETCH_PATIENT_Q_AND_A, PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES,
        PHYSICIAN_SAVE_REFER_NOTES, PHYSICIAN_FETCH_REFERRED_PATIENTS, PHYSICIAN_FETCH_REFERENCE_NOTES, FETCH_USERS, FETCH_USERS_IN_PROGRESS, FETCH_USERS_SUCCESS, FETCH_USERS_FAILED, FETCH_SOURCE_PATIENT_DETAILS, SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT
    ]
}
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['account', 'physicianAccount', 'practitionerAccount'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const saga = createSagaMiddleware();
const logger = (store: any) => (next: any) => (action: any) => {
    console.log(action)
    return next(action)
}

const addAccount = (store: any) => (next: any) => (action: any) => {
    if (ACTIONS_REQUIRING_AUTH.includes(action.type)) {
        const account: AccountParams = store.getState().account;
        const physicianAccount: PhysicianAccountParams = store.getState().physicianAccount;
        const token = account.token
        const accountId = account.id
        const email = account.email
        const role = account.role
        const practiceLocationId = physicianAccount.defaultLocation?.id

        if (token && accountId && email && role) {
            action.authState = { token, accountId, email, role, practiceLocationId } as AuthState
        }
    }
    return next(action)
}

const checkRole = (store: any) => (next: any) => (action: any) => {
    if (ACTIONS_REQUIRING_AUTH.includes(action.type)) {
        const account: PractitionerAccountParams = store.getState().account;
        const role = account.role
        console.log(ACTIONS_ALLOWED_BY_ROLE)
        if (role) {
            const accountAllowedByRole = ACTIONS_ALLOWED_BY_ROLE[role].includes(action.type)
            if (!accountAllowedByRole) {
                console.log(`Not authorized to perform this action by role ${role}`, action)
                return next(unauthorizedAccessForYourRole({ role: role }))
            } else {
                console.log("Authorized to perform this action by role", role, action)
                return next(action)
            }
        } else {
            console.log("Not authorized to perform this action because the role is unknown", action)
            return next(unauthorizedAccessForYourRole({ role: "Unknown Role" }))
        }
    }
    return next(action)
}

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(logger, addAccount, checkRole, saga)));
saga.run(rootSaga);

export function setupStore(preloadedState?: PreloadedState<any>) {
    return createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware(logger, addAccount, checkRole, saga)))
}
export type AppStore = ReturnType<typeof setupStore>

type Action = typeof ACTIONS_REQUIRING_AUTH[number];
const hasPermission = (action: Action) => {
    const role = store.getState().account.role
    if (role) {
        const accountAllowedByRole = ACTIONS_ALLOWED_BY_ROLE[role].includes(action)
        return accountAllowedByRole
    }
    return false
}

const persistor = persistStore(store);
export {
    store,
    persistor,
    saga,
    hasPermission
};
