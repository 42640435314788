import React, {useCallback, useEffect} from "react";
import {IoCallOutline, IoInformationCircle} from "react-icons/io5";
import {Button} from "../../../components/generic/Buttons";
import CallModalComponent from "../../prospects/care-coordinator/CallModalComponent";
import {useDispatch} from "react-redux";
import {
    IPatientInCall, IPhysicianAppointmentLocation,
    IPhysicianAppointmentPatient,
    IPhysicianAppointmentPractice,
    LocationIndexItem
} from "../../../models";
import {
    clearLocationIdToInitiateCall,
    setLocationIdToInitiateCall
} from "../../../store/actions/physician/admin-operations.action";
import {requestCall} from "../../../store/actions/counsellor/calls.action";
import {toast} from "react-toastify";
import {MaskPhoneNumber} from "../../../components/generic/MaskPhoneNumber";
import ConfirmationModal from "../../../components/clinical-notes/ConfirmationModal";
import {isPhoneNumberValid} from "../../../shared/Utils";
import {Tooltip} from "react-tooltip";
import {DateTime} from "luxon";
import EditablePhoneNumber from "../../../components/calls/EditablePhoneNumber";

interface IPhysicianAppointmentCallPatientComponentProps {
    value: {
        appointmentId: string;
        patient: IPhysicianAppointmentPatient;
        practice: IPhysicianAppointmentPractice;
        location: IPhysicianAppointmentLocation;
    },
    fetchPhysicianAppointmentList: () => void;
}

const PhysicianAppointmentCallPatientComponent = (props: IPhysicianAppointmentCallPatientComponentProps) => {

    const {value, fetchPhysicianAppointmentList} = props;
    const dispatch = useDispatch();
    const {appointmentId, location, practice, patient} = value;
    const [patientPhoneNumber, setPatientPhoneNumber] = React.useState<string>(patient?.contactPhoneNumber);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] = React.useState<boolean | undefined>(undefined);
    const [isLocationPhoneVerified, setIsLocationPhoneVerified] = React.useState<boolean | undefined>(undefined);
    const [isEditPhoneNumberModalOpen, setIsEditPhoneNumberModalOpen] = React.useState<boolean>(false);

    const handleOpenModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
        if (patientPhoneNumber !== patient?.contactPhoneNumber) { // refresh the list only if the phone number is updated so that the updated phone number is shown in the list
            fetchPhysicianAppointmentList();
        }
    }, [fetchPhysicianAppointmentList, patient, patientPhoneNumber]);

    const initiateCall = useCallback(() => {
        handleCloseModal();
        const patientToCall: IPatientInCall = {
            id: patient?.id,
            sourcePatientId: patient?.id,
            phoneNumber: patientPhoneNumber,
            referredByPracticeId: practice?.id,
            dob: DateTime.fromISO(patient?.dob).toJSDate(),
            firstName: patient?.firstName,
            lastName: patient?.lastName
        }
        dispatch(setLocationIdToInitiateCall(location.id));
        dispatch(requestCall(patientToCall));
    }, [location, patient, practice, patientPhoneNumber, dispatch, handleCloseModal]);

    useEffect(() => {
        return () => {
            dispatch(clearLocationIdToInitiateCall());
        }
    }, [dispatch]);

    const handleEditPhoneNumberModalOpen = useCallback(() => {
        setIsEditPhoneNumberModalOpen(true);
    }, []);

    const handleEditPhoneNumberModalClose = useCallback(() => {
        setIsEditPhoneNumberModalOpen(false);
    }, []);

    const renderEditPhoneNumberModal = useCallback(() => {
        return <ConfirmationModal
            modalClassNames={'w-[500px]'}
            isOpen={isEditPhoneNumberModalOpen}
            onClose={handleEditPhoneNumberModalClose}
        >
            <EditablePhoneNumber
                patient={{
                    ...patient,
                    dob: DateTime.fromISO(patient.dob).toJSDate(),
                    phoneNumber: patientPhoneNumber,
                    referredByPracticeId: practice.id,
                    sourcePatientId: patient.id
                }}
                editMode={true}
                onEdit={(patientPhoneNumber)=>{
                    setPatientPhoneNumber(patientPhoneNumber);
                    handleEditPhoneNumberModalClose();
                }}
                onCancel={handleEditPhoneNumberModalClose}
            />
        </ConfirmationModal>
    }, [isEditPhoneNumberModalOpen, setIsEditPhoneNumberModalOpen, patient, practice, handleEditPhoneNumberModalClose, fetchPhysicianAppointmentList]);

    const renderToolTipForInvalidPhoneNumber = useCallback(() => {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}}
                        id={`${appointmentId}-physician-appointment-invalid-phone-number-tooltip`}>
            <div>
                The patient phone number is invalid. Please update the phone number.
            </div>
        </Tooltip>
    }, [appointmentId, location?.name, isPatientPhoneNumberValid]);

    const renderToolTipForLocationPhoneNotVerified = useCallback(() => {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}}
                        id={`${appointmentId}-physician-appointment-call-patient-tooltip`}>
            <div>
                Location phone number is not verified. Please contact admin.
            </div>
        </Tooltip>
    }, [appointmentId]);

    const renderCallModal = useCallback(() => {
        const dob = DateTime.fromISO(patient?.dob, {zone: 'utc'}).toFormat('MM/dd/yyyy');
        return <ConfirmationModal isOpen={isModalOpen}
                                  onClose={handleCloseModal}
                                  key={'callModal'}
                                  modalClassNames={'w-[500px]'}
                                  alignContentInCenter={false}
                                  actions={<div className="flex gap-2 justify-end">
                                      <button
                                          className="p-2 px-5 rounded-md text-sm border border-solid border-sjOrange text-sjOrange"
                                          onClick={handleEditPhoneNumberModalOpen}>
                                          Edit Phone Number
                                      </button>
                                      <button
                                          data-tooltip-id={`${appointmentId}-physician-appointment-invalid-phone-number-tooltip`}
                                          className={`p-2 px-5 text-sm bg-sjOrange rounded-md text-sjWhite border-solid border-l-sjOrange ${!isPatientPhoneNumberValid ? "cursor-not-allowed bg-sjGray text-sjGray" : ""}`}
                                          disabled={!isPatientPhoneNumberValid}
                                          onClick={event => {
                                              if (!isPatientPhoneNumberValid) {
                                                  toast.error("Patient phone number is invalid. Please update the phone number.");
                                                  return;
                                              } else {
                                                  initiateCall();
                                              }
                                          }}>
                                          Call Patient
                                      </button>
                                  </div>
                                  }
        >
            <header className="text-[20px] leading-27">{`Call ${patient?.firstName} ${patient?.lastName}`} ({dob})
            </header>
            <p className="text-sm text-[#5D7285] text-opacity-60 leading-22">
                {`Call patient having the phone number: `}<MaskPhoneNumber value={patientPhoneNumber}
                                                                           disabled={true}
                                                                           className={'border-0 bg-transparent'}/>
            </p>
            {renderEditPhoneNumberModal()}
            {!isPatientPhoneNumberValid && renderToolTipForInvalidPhoneNumber()}
        </ConfirmationModal>
    }, [isModalOpen, appointmentId, handleCloseModal, initiateCall, patient, patientPhoneNumber, handleEditPhoneNumberModalOpen, renderEditPhoneNumberModal, isPatientPhoneNumberValid]);

    useEffect(() => {
        console.log("VoipPhoneNumber.tsx :: useEffect :: check patientPhoneNumber validity :: ", patientPhoneNumber);
        if (isPhoneNumberValid(patientPhoneNumber)) {
            setIsPatientPhoneNumberValid(true);
        } else {
            setIsPatientPhoneNumberValid(false);
        }
    }, [patient, patientPhoneNumber]);

    useEffect(() => {
        if (location?.isPhoneVerified) {
            setIsLocationPhoneVerified(true);
        } else {
            setIsLocationPhoneVerified(false);
        }
    }, [location?.isPhoneVerified])

    const handleCall = useCallback(() => {
        if (!isLocationPhoneVerified) {
            return;
        } else {
            handleOpenModal();
        }
    }, [isLocationPhoneVerified, handleOpenModal]);

    return <>
        <Button
            data-tooltip-id={`${appointmentId}-physician-appointment-call-patient-tooltip`}
            className={`rounded-l-md !ml-2 px-[8px] py-[8px] !h-8 text-sm ${!isLocationPhoneVerified ? "border-sjGray text-sjGray" : "border-sjOrange text-sjOrange"}`}
            onClick={handleCall}>
            <IoCallOutline/>
        </Button>
        {isModalOpen && renderCallModal()}
        {!isLocationPhoneVerified && renderToolTipForLocationPhoneNotVerified()}
    </>
}

export default PhysicianAppointmentCallPatientComponent;
